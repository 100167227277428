import React from "react";

import Layout from "../layouts";

const NotFoundPage = () => (
  <Layout>
    <div>
      <h1>404 - Page Not Found</h1>
      <p>This page doesn't exist</p>
      <p>Or it just hasn't been created yet. </p>
      <p>Check back soon!</p>
    </div>
  </Layout>
);

export default NotFoundPage;
